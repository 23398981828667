
import { defineComponent } from "vue";
import { useApi } from "@/api/Api";

export default defineComponent({
	name: "System",
	components: {},
	setup() {
		return { ...useApi() };
	},
});
